<template>
    <table class="table">
        <thead>
            <tr>
                <th>Total</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th v-if="fileName == 'BalanceSheet' && fileName == 'IncomeStatement'"></th>
                <th style="text-align: center" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">{{
                    total.total_opening_balance_debit }}</th>
                <th style="text-align: center" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">{{
                    total.opening_balance_credit }}</th>
                <th style="text-align: center" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">{{
                    total.transaction_debit }}</th>
                <th style="text-align: center" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">{{
                    total.transaction_credit }}</th>
                <th>{{ total.debit_close }}</th>
                <th>{{ total.credit_close }}</th>
                <th v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'"></th>
            </tr>
        </thead>
    </table>
</template>
  
<script>
export default {
    name: "TreeTableTotal",
    props: {
        total: {
            type: Object,
            default: () => {
                return {};
            },
        },
        fileName: {
            type: String,
            default: "must give file name"
        }
    },

};
</script>
  
<style scoped>
@import "common.css";

.width {
    width: 250px;
}

.less {
    width: 150px;
    padding-left: 30px;
}
</style>
  