<template>
  <div>
    <div class="row-tree-table" :style="getRowStyle(rowData)">
      <div class="cell-tree-table node node-style">
        <div class="open-button" @click="toggle">
          <!-- <div
            v-if="!isOpen"
            class="closed"
            :style="{ paddingLeft: leftPadding + 'px' }"
          />
          <div
            v-if="isOpen"
            class="open"
            :style="{ paddingLeft: leftPadding + 'px' }"
          /> -->
          <div class="indentation" :style="{ paddingLeft: leftPadding + 'px' }" />
          <div v-if="!isOpen" class="closed" />
          <div v-if="isOpen" class="open" />
        </div>
        <div>
          {{ rowData[columnWithOpenButton] }}
        </div>
      </div>
      <div v-for="key in reguralColumns" :key="key" class="cell-tree-table node-style">
        {{ rowData[key] }}
      </div>
      <!-- <button class="btn btn-theme m-1" @click="rowClick(rowData)" style="width: 3rem; height: 3rem;">
        <b-icon-printer> </b-icon-printer> </button> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  name: "TreeDefaultNode",
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultOrder: {
      type: Array,
      default: () => {
        return [];
      },
    },
    depth: {
      type: Number,
      default: 0,
    },
    onToggle: {
      type: Function,
      default: () => { },
    },
    onOpen: {
      type: Function,
      default: () => { },
    },
    onClose: {
      type: Function,
      default: () => { },
    },
    rowClick: {
      type: Function,
      default: () => {
        return {}
      }
    },
    toggleSelection: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      isOpen: false,
      itemId: 0,
    };
  },
  computed: {
    columnWithOpenButton: function () {
      return this.defaultOrder[0];
    },
    reguralColumns: function () {
      return this.defaultOrder.slice(1);
    },
    leftPadding: function () {
      return this.depth * 16;
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.onToggle();
    },
    getRowStyle(rowData) {
      if (this.toggleSelection === "adjustable" && rowData.type === "adjustable") {
        return { backgroundColor: "#28a745" };
      } else if (this.toggleSelection === "unadjustable" && rowData.type === "unadjustable") {
        return { backgroundColor: "#ffc107" };
      } else {
        return {}; // Default style for other cases
      }
    }
  },
};
</script>

<style scoped>
@import "common.css";

.node-style {
  font-weight: bold;
  font-size: 13px;
}

.open-button {
  float: left;
  display: inline;
  padding-right: 0.5rem;
}

.closed::before {
  content: "▸";
}

.open::before {
  content: "▾";
}

.node {
  flex: 0 1 200px;
}
</style>
