<template>
  <div>
    <slot name="headerTemplate" :columns="columns">
      <TreeTableHeader :columns="columns" :fileName="fileName" />
    </slot>

    <TreeBody :table-data="tableData" :columns="columnsOrder" :rowClick="rowClick" :toggleSelection="toggleSelection">
      <template #nodeTemplate="nodeProps">
        <slot name="nodeTemplate" v-bind="nodeProps" :rowClick="rowClick" :toggleSelection="toggleSelection" />
      </template>

      <template #leafTemplate="leafProps">
        <slot name="leafTemplate" v-bind="leafProps" :rowClick="rowClick" :toggleSelection="toggleSelection" />
      </template>
    </TreeBody>
    <TreeTableTotal :total="total" :fileName="fileName" />
  </div>
</template>

<script>
import TreeBody from "./TreeBody";
import TreeTableHeader from "./TreeTableHeader";
import TreeTableTotal from "./TreeTableTotal.vue"
// import { uuid } from '';
import { uuid } from "vue-uuid";

export default {
  name: "TreeTable",
  components: { TreeBody, TreeTableHeader, TreeTableTotal },
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    total: {
      type: Object,
      default: () => {
        return {};
      },
    },
    rowClick: {
      type: Function,
      default: () => {
        return {}
      }
    },
    toggleSelection: {
      type: String,
      default: null
    },
    fileName: {
      type: String,
      default: "must give file name"
    }
  },
  computed: {
    columnsOrder() {
      return this.columns.map((column) => column.id);
    },
  },
  created() {
    this.enrichTableData();
  },
  methods: {
    addUniqueId(data) {
      data.uuid = uuid.v4();
      if (data.children) {
        data.children.forEach((child) => this.addUniqueId(child));
      }
    },
    enrichTableData() {
      this.tableData.forEach((el) => this.addUniqueId(el));
    },

  },
};
</script>

<style scoped></style>
