<template>
  <table class="table">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th style="text-align: start" colspan="2" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">
          Opening Balance</th>
        <th style="text-align: start" colspan="2" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">
          Transactions</th>
        <th style="text-align: start" colspan="2">
          Closing Balance</th>
      </tr>
      <tr>
        <th>Code</th>
        <th :style="getRowStyle()">Account Title</th>
        <th style="text-align: start" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">Debit</th>
        <th style="text-align: start" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">Credit</th>
        <th style="text-align: start" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">Debit</th>
        <th style="text-align: start" v-if="fileName !== 'BalanceSheet' && fileName !== 'IncomeStatement'">Credit</th>
        <th style="text-align: start">Debit</th>
        <th style="text-align: start">Credit</th>
      </tr>
    </thead>
  </table>
</template>

<script>
export default {
  name: "TreeTableHeader",
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fileName: {
      type: String,
      default: "must give file name"
    }
  },
  methods: {
    getRowStyle() {
      if (this.fileName === "BalanceSheet" || this.fileName === "IncomeStatement") {
        return { textAlign: "start" };
      } else {
        return { textAlign: "center" };
      }
    }
  },
};
</script>

<style scoped>
@import "common.css";

.width {
  width: 250px;
}

.less {
  width: 150px;
  padding-left: 30px;
}


.table {
  position: sticky;
  top: 0;
}

thead {
  position: sticky;
  top: 0;
}

th {
  position: sticky;
  top: 0;
}
</style>
