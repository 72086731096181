<template>
  <div class="row-tree-table node" :style="getRowStyle(rowData)">
    <div class="open-button">
      <div class="indentation" :style="{ width: leftPadding + 'px' }" />
    </div>

    <div v-for="key in defaultOrder" :key="key" class="cell-tree-table">
      {{ rowData[key] }}
    </div>
    <!-- <button class="btn btn-theme m-1" @click="rowClick(rowData)" style="width: 3rem; height: 3rem;">
      <b-icon-printer> </b-icon-printer> </button> -->
  </div>
</template>

<script>
export default {
  components: {},
  name: "TreeDefaultLeaf",
  data: function () {
    return {
      itemId: 0,
    };
  },
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultOrder: {
      type: Array,
      default: () => {
        return [];
      },
    },
    depth: {
      type: Number,
      default: 0,
    },
    rowClick: {
      type: Function,
      default: () => {
        return {}
      }
    },
    toggleSelection: {
      type: String,
      default: null
    }
  },
  computed: {
    leftPadding: function () {
      return this.depth * 16;
    },
  },
  methods: {
    getRowStyle(rowData) {
      if (this.toggleSelection === "adjustable" && rowData.type === "adjustable") {
        return { backgroundColor: "#28a745" };
      } else if (this.toggleSelection === "unadjustable" && rowData.type === "unadjustable") {
        return { backgroundColor: "#ffc107" };
      } else {
        return {}; // Default style for other cases
      }
    }
  },
};
</script>

<style scoped>
@import "common.css";

.node {
  flex: 0 1 200px;
}

.open-button {
  float: left;
  display: inline;
  padding-right: 0.5rem;
}
</style>
